<template>

    <el-card style="box-shadow: none">
      <div slot="header" class="clearfix">
        <el-form
            id="change"
            status-icon
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
        >客户名称：
          <el-input
              style="width: 200px; margin-right: 10px"
              class="search"
              v-model="asearch1"
              prefix-icon="el-icon-search"
              clearable
              placeholder="请输入客户名称"
          />
          客户状态：
          <el-select v-model="value" placeholder="请选择" clearable  style="margin-right: 10px">
            <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.label"
                :value="item.id"
            >
            </el-option>
          </el-select>
          <el-button type="primary" @click="getCustomerListByParam()" style="background: #1d90ff; border:none">查询</el-button>
          <el-button type="success" @click="addArticle()" style="background: #1d90ff; border:none">添加客户</el-button>
        </el-form>
      </div>
      <el-table
          stripe
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%;margin-top:10px"
          border
      >
        <el-table-column prop="id" label="客户编号" width="100" align="center">
        </el-table-column>
        <el-table-column prop="name" label="客户名称" width="150" align="center">
        </el-table-column>
        <el-table-column
            prop="serverStartTime"
            label="服务开始时间"
            width="150"
            align="center">
        </el-table-column>
        <el-table-column prop="serverEndTime" label="服务结束时间" width="150" align="center">
        </el-table-column>
        <el-table-column label="状态" width="80" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0" style="color: gray">关闭</span>
            <span v-if="scope.row.status == 1" style="color: blue">进行中</span>
            <span v-if="scope.row.status == 2" style="color: green">已上线</span>
            <span v-if="scope.row.status == 3" style="color: red">异常</span>
          </template>
        </el-table-column>
        <el-table-column label="状态修改" width="120" align="center" v-if="userInfo.userType==-99">
          <!-- eslint-disable-next-line -->
          <template slot-scope="scope">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link" style="color:orange;">
                状态修改<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="adminUpdateCustomerStatus(scope.row.id,0)" icon="el-icon-remove-outline"
                                  style="color:gray;">关闭
                </el-dropdown-item>
                <el-dropdown-item @click.native="adminUpdateCustomerStatus(scope.row.id,1)" icon="el-icon-circle-plus-outline"
                                  style="color:blue;">进行中
                </el-dropdown-item>
                <el-dropdown-item @click.native="adminUpdateCustomerStatus(scope.row.id,2)" icon="el-icon-circle-check"
                                  style="color:green;">已上线
                </el-dropdown-item>
                <el-dropdown-item @click.native="adminUpdateCustomerStatus(scope.row.id,3)" icon="el-icon-circle-close"
                                  style="color:red;">异常
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <!-- eslint-disable-next-line -->
        </el-table-column>
        <el-table-column prop="updatedTime" label="更新时间" align="center" width="150"></el-table-column>
        <el-table-column fixed="right" label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button type="success" style="background: #1d90ff; border:none"  size="mini"  @click="editArticle(scope.row.id)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center;margin-top: 20px">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[10, 20, 50, 60]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total=pagetotal>
        </el-pagination>
      </div>
    </el-card>
</template>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<script>
import axios from "axios";
import $ from "jquery";
import Cookies from "js-cookie";

export default {
  methods: {
    getCustomerList() {
      this.$api.get(
          "/customer/list",
          null,
          null,
          (successRes) => {
            
            var exf = successRes.data.rows;
            for (var i = 0; i< exf.length;i++){
            exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
            exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
            exf[i].updatedTime = exf[i].updatedTime.substring(0,10);
            exf[i].serverStartTime = exf[i].serverStartTime.substr(0, 19);
            exf[i].serverStartTime = new Date(+new Date(exf[i].serverStartTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
            exf[i].serverStartTime = exf[i].serverStartTime.substring(0,10);
            exf[i].serverEndTime = exf[i].serverEndTime.substr(0, 19);
            exf[i].serverEndTime = new Date(+new Date(exf[i].serverEndTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
            exf[i].serverEndTime = exf[i].serverEndTime.substring(0,10);
            }
            this.tableData = exf;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    adminUpdateCustomerStatus(id, status) {
      this.$api.post(
          "/customer/admin/updateCustomerStatus",
          null,
          {
            id: id,
            status: status
          },
          (successRes) => {
            console.log(successRes);
            if (successRes.status == 200) {
              this.$message('操作成功');
              this.getCustomerList();
            } else {
              this.$message(successRes.message);
            }
          },
          (failureRes) => {
            this.$message('操作失败');
          }
      );
    },
    getUserInfoByUserName() {
      const username = Cookies.get("username");
      this.$api.get(
          "/user/getUserInfoByUserName",
          null,
          {
            username: username
            
          },
          (successRes) => {
            this.userInfo = successRes.data;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    getCustomerListByParam() {
      this.$api.get(
          "/customer/list",
          null,
          {
            name: this.asearch1,
            status: this.value,
          },
          (successRes) => {
            var exf = successRes.data.rows;
            for (var i = 0; i< exf.length;i++){
            exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
            exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
            exf[i].updatedTime = exf[i].updatedTime.substring(0,10);
            exf[i].serverStartTime = exf[i].serverStartTime.substr(0, 19);
            exf[i].serverStartTime = new Date(+new Date(exf[i].serverStartTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
            exf[i].serverStartTime = exf[i].serverStartTime.substring(0,10);
            exf[i].serverEndTime = exf[i].serverEndTime.substr(0, 19);
            exf[i].serverEndTime = new Date(+new Date(exf[i].serverEndTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
            exf[i].serverEndTime = exf[i].serverEndTime.substring(0,10);
            }
            this.tableData = exf;
            
            
            // this.tableData = successRes.data.rows;
            this.pagetotal =  successRes.data.total;
            console.log(this.pagetotal);

          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },

    handleClick(row) {
      console.log(row);
      
    },
    editArticle(id) {
      this.$router.push({ path: 'customeredit',query: {id: id}});

    },
    addArticle() {
      this.$router.push({name: "customerAdd", params: {userId: 123}});
    },
     handleSizeChange(val) {
       this.spagetotal=val;
         this.$api.get(
          "/customer/list",
          null,
          {
            page:this.nowpagetotal,
          record:this.spagetotal,
          name: this.asearch1,
            status: this.value,
          },
          (successRes) => {
            this.pagetotal =  successRes.data.total;
             var exf = successRes.data.rows;
            for (var i = 0; i< exf.length;i++){
            exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
            exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
            exf[i].updatedTime = exf[i].updatedTime.substring(0,10);
            exf[i].serverStartTime = exf[i].serverStartTime.substr(0, 19);
            exf[i].serverStartTime = new Date(+new Date(exf[i].serverStartTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
            exf[i].serverStartTime = exf[i].serverStartTime.substring(0,10);
            exf[i].serverEndTime = exf[i].serverEndTime.substr(0, 19);
            exf[i].serverEndTime = new Date(+new Date(exf[i].serverEndTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
            exf[i].serverEndTime = exf[i].serverEndTime.substring(0,10);
            }
            this.tableData = exf;
            console.log(this.pagetotal);

          },
          (failureRes) => {
            console.log(failureRes);
          }
      );

        console.log(val);
      },
      handleCurrentChange(val) {
        this.nowpagetotal=val;
        this.$api.get(
          "/customer/list",
          null,
          {
            page:this.nowpagetotal,
          record:this.spagetotal,
          name: this.asearch1,
            status: this.value,
          },
          (successRes) => {
            this.pagetotal =  successRes.data.total;
            var exf = successRes.data.rows;
            for (var i = 0; i< exf.length;i++){
            exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
            exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
            exf[i].updatedTime = exf[i].updatedTime.substring(0,10);
            exf[i].serverStartTime = exf[i].serverStartTime.substr(0, 19);
            exf[i].serverStartTime = new Date(+new Date(exf[i].serverStartTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
            exf[i].serverStartTime = exf[i].serverStartTime.substring(0,10);
            exf[i].serverEndTime = exf[i].serverEndTime.substr(0, 19);
            exf[i].serverEndTime = new Date(+new Date(exf[i].serverEndTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
            exf[i].serverEndTime = exf[i].serverEndTime.substring(0,10);
            }
            this.tableData = exf;
            console.log(this.pagetotal);

          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
      }
  },
  data() {
    return {
      options: [
        {
          id: 0,
          value: "选项1",
          label: "关闭",
        },
        {
          id: 1,
          value: "选项2",
          label: "进行中",
        },
        {
          id: 2,
          value: "选项3",
          label: "已上线",
        },
        {
          id: 3,
          value: "选项4",
          label: "已异常",
        },
      ],
      value:'',
      tableData: [],
      asearch1: "",
      asearch2: "",
      asearchdata: {},
      userInfo: {},
      pagetotal:0,
      spagetotal:'10',
      nowpagetotal:'1',
      currentPage4: 1
    };
  },
  mounted() {
    this.getCustomerListByParam();
    this.getUserInfoByUserName();
  },
};
</script>